<template>
  <div>
    <Navbar />
    <div class="page-heading">
      <h3>Certificados : {{ nombre }}</h3>
    </div>
    <div class="page-content">
      <section class="section">
        <div class="row">
          <div v-show="cargando == true">
            <button class="btn btn-warning" type="button" disabled="">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          </div>
        </div>
        <div
          class="row table-responsive"
          id="table-responsive"
          v-show="cargando == false"
        >
          <div class="col-md-12">
            <div class="card">
              <div class="card-body px3 py-4-5">
                <div class="">
                  <table class="table table-bordered" id="sampleTable">
                    <thead>
                      <tr>
                        <th style="width: 25px !important">Opciones</th>
                        <th scope="col">DNI</th>
                        <th scope="col">Nombres y Apellidos</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="m in lista" :key="m.id">
                        <th scope="row">
                          <template v-if="m.certificado == null">
                            <button
                              title="Nuevo certificado"
                              type="button"
                              class="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#crear"
                              @click="datosdeMatricula(m.id)"
                            >
                              <svg
                                class="svg-inline--fa fa-folder-plus fa-w-16 fa-fw select-all"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="folder-plus"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="currentColor"
                                  d="M464,128H272L208,64H48A48,48,0,0,0,0,112V400a48,48,0,0,0,48,48H464a48,48,0,0,0,48-48V176A48,48,0,0,0,464,128ZM359.5,296a16,16,0,0,1-16,16h-64v64a16,16,0,0,1-16,16h-16a16,16,0,0,1-16-16V312h-64a16,16,0,0,1-16-16V280a16,16,0,0,1,16-16h64V200a16,16,0,0,1,16-16h16a16,16,0,0,1,16,16v64h64a16,16,0,0,1,16,16Z"
                                ></path>
                              </svg>
                              Nuevo
                            </button>
                          </template>
                          <template v-else>
                            <div
                              class="btn-group"
                              role="group"
                              aria-label="Basic mixed styles example"
                            >
                              <button
                                type="button"
                                title="Eliminar"
                                class="btn btn-danger"
                                @click="eliminarCertificado(m.certificado.id)"
                              >
                                <i class="bi bi-x-octagon-fill"></i>
                              </button>
                              <button
                                type="button"
                                title="Editar"
                                data-bs-toggle="modal"
                                data-bs-target="#editar"
                                @click="
                                  cargarDatosCertificado(m.certificado.id)
                                "
                                class="btn btn-warning"
                              >
                                <i class="bi bi-pen-fill"></i>
                              </button>

                              <button
                                type="button"
                                class="btn btn-success"
                                @click="
                                  generarPDF(
                                    m.certificado.id,
                                    cursoselecionado.id,
                                    cursoselecionado.precio_suficiencia,
                                    m.alumno
                                  )
                                "
                              >
                                <svg
                                  class="svg-inline--fa fa-cloud-download-alt fa-w-20 fa-fw select-all"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="cloud-download-alt"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 640 512"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="currentColor"
                                    d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </template>
                        </th>

                        <td>{{ m.alumno.dni }}</td>

                        <td>
                          {{ m.alumno.nombres }} {{ m.alumno.a_paterno }}
                          {{ m.alumno.a_materno }}
                        </td>
                        <td>{{ m.alumno.email }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="crear"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary text-center">
            <h5 class="modal-title text-white" id="exampleModalLabel">
              Crear Certificado
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <p class="text-center mt-2 fw-bold">
                {{ alumno.dni }}-{{ alumno.nombre }}
              </p>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="basicInput">Horas Pedagógicas</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="certificado.lado_uno"
                  />
                </div>
                <div class="form-group">
                  <label for="basicInput">Fecha de Inicio</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="certificado.fecha_inicio"
                  />
                </div>
                <div class="form-group">
                  <label for="basicInput">Fecha de Fin</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="certificado.fecha_fin"
                  />
                </div>

                <div class="form-group">
                  <label for="helpInputTop">Fecha Emisión</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="certificado.created_at"
                  />
                </div>
                <div class="form-group">
                  <label for="helpInputTop">Nota</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="certificado.nota"
                  />
                </div>
                <div class="form-group">
                  <input type="radio" id="uno" value="1" v-model="certipo" />
                  <label for="uno"> Tipo 01</label>
                  <br />
                  <input type="radio" id="Dos" value="2" v-model="certipo" />
                  <label for="Dos"> Tipo 02</label>
                  <br />
                  <input type="radio" id="Tres" value="3" v-model="certipo" />
                  <label for="Tres"> Colegio Abogados</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-show="cargando == false"
              type="button"
              class="btn btn-primary"
              @click="
                generarCertificado(certificado, idmatriculaseleccionada, alumno)
              "
            >
              Generar
            </button>
            <button
              v-show="cargando == false"
              type="button"
              class="btn btn-outline-danger"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="editar"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary text-center">
            <h5 class="modal-title text-white" id="exampleModalLabel">
              Editar Certificado
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="basicInput">Horas Pedagógicas</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="ceditar.horas_creado"
                  />
                </div>

                <div class="form-group">
                  <label for="helpInputTop">Fecha Inicio</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="ceditar.fecha_inicio"
                  />
                </div>
                <div class="form-group">
                  <label for="helpInputTop">Fecha Fin</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="ceditar.fecha_fin"
                  />
                </div>
                <div class="form-group">
                  <label for="helpInputTop">Fecha Emisión</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="ceditar.fecha_creado"
                  />
                </div>
                <div class="form-group">
                  <label for="helpInputTop">Nota</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="ceditar.nota"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="radio"
                    id="uno"
                    value="1"
                    v-model="ceditar.certipo"
                  />
                  <label for="uno"> Tipo 01</label>
                  <br />
                  <input
                    type="radio"
                    id="Dos"
                    value="2"
                    v-model="ceditar.certipo"
                  />
                  <label for="Dos"> Tipo 02</label>
                  <br />
                  <input
                    type="radio"
                    id="Tres"
                    value="3"
                    v-model="ceditar.certipo"
                  />
                  <label for="Dos"> Colegio de Agobados</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-show="cargando == false"
              type="button"
              class="btn btn-primary"
              @click="editarCertificado(ceditar)"
            >
              Generar
            </button>
            <button
              v-show="cargando == false"
              type="button"
              class="btn btn-outline-danger"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
require("datatables.net-buttons/js/dataTables.buttons")();
require("datatables.net-buttons/js/buttons.html5.js")();
import $ from "jquery";
import { LISTAR_ALUMNOS } from "@/graphql/queries";
import { UN_CURSO } from "@/graphql/queries";
import { MATRICULA_ID } from "@/graphql/queries";
import { CREAR_CERTIFICADO } from "@/graphql/mutations";
import { CERTIFICADO_UPDATE } from "@/graphql/mutations";
import { CERTIFICADO_ELIMINAR } from "@/graphql/mutations";
import { CERTIFICADO_PDF } from "@/graphql/queries";

/*Email js */
import { EMAIL_JS_1 } from "@/graphql/queries";

import jszip from "jszip/dist/jszip";
window.JSZip = jszip;
import moment from "moment";

export default {
  name: "Edit",
  data() {
    return {
      lista: [],
      nombre: "",
      idcertificado_creado: 0,
      horas_creado: 0,
      fecha_creado: 0,
      ecorreo:{},
      matricula: null,
      curso: null,
      cursoselecionado: {},
      alumno: {
        dni: 0,
        nombre: "",
      },
      ceditar: {
        idcertificado_creado: 0,
        horas_creado: 0,
        fecha_creado: 0,
        fecha_inicio: "",
        fecha_fin: "",
        nota: "",
        certipo: 0,
      },

      cargando: false,
      idmatriculaseleccionada: 0,
      certificado: {
        created_at: new Date().toISOString().slice(0, 10),
        matricula_id: "",
        nota: 20,
        lado_uno: 200,
        lado_dos: "b",
        tipo: "2",
        taller_dias: "0",
        codigo: " ",
        fecha_inicio: new Date().toISOString().slice(0, 10),
        fecha_fin: new Date().toISOString().slice(0, 10),
      },
      certipo: 2,
      service_id: "service_m00ohpb",
      template_id: "template_ts46uoe",
    };
  },
  methods: {
    tabla() {
      this.$nextTick(() => {
        var table = $("#sampleTable").DataTable({
          dom:
            "<'row '<'col-sm-12  'B>><'row '<'col-sm-12 col-md-6 'l><'col-sm-12 col-md-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
          buttons: [
            {
              extend: "excelHtml5",
              text: "<i class='bi bi-file-earmark-excel'></i> Excel",
              titleAttr: "Exportar a Excel",
              className: "btn btn-success",
              title: this.nombre,
            },
          ],
        });
        table.order([0, "desc"]).draw();
      });
    },

    llamareEmailjs() {      
      this.$apollo
        .query({
          query: EMAIL_JS_1,
          variables: {
            id: 2,
          },
        })
        .then((data) => {
          this.ecorreo = data.data.user;
          this.ecorreo.public=this.ecorreo.nombres
          this.ecorreo.service=this.ecorreo.a_paterno
          this.ecorreo.template=this.ecorreo.a_materno
          
        });
    },

    listarAlumnos() {
      this.cargando = true;
      let idcurso = parseInt(this.$route.params.idc);
      this.$apollo
        .query({
          query: LISTAR_ALUMNOS,
          variables: {
            id: idcurso,
            curso_id: idcurso,
          },
        })
        .then((data) => {
          this.lista = data.data.matriculasByCursoId;
          this.cargando = false;
          $("#sampleTable").DataTable().destroy();
          this.tabla();
        });
    },
    llamarCurso() {
      let idcurso = parseInt(this.$route.params.idc);
      this.$apollo
        .query({
          query: UN_CURSO,
          variables: {
            id: idcurso,
          },
        })
        .then((data) => {
          this.cursoselecionado = data.data.curso;
          this.nombre = data.data.curso.nombre_completo;
        });
    },
    datosdeMatricula(id) {
      let idmatricula = parseInt(id);
      this.idmatriculaseleccionada = idmatricula;
      this.$apollo
        .query({
          query: MATRICULA_ID,
          variables: {
            id: idmatricula,
          },
        })
        .then((data) => {
          this.matricula = data.data.matricula;
          this.certificado.matricula_id = parseFloat(this.matricula.id);
          this.certificado.lado_uno = this.matricula.curso.horas_pedagogicas;
          this.alumno.nombre =
            this.matricula.alumno.nombres +
            " " +
            this.matricula.alumno.a_paterno +
            " " +
            this.matricula.alumno.a_materno;
          this.alumno.dni = this.matricula.alumno.dni;
          this.alumno.email = this.matricula.alumno.email;
          this.curso = this.matricula.curso.nombre_completo;
          this.idcurso = this.matricula.curso.id;
        });
    },
    generarCertificado(certificado, idmatricula, alumno) {
      this.cargando = true;

      this.$apollo
        .mutate({
          mutation: CREAR_CERTIFICADO,
          variables: {
            codigo: certificado.codigo,
            matricula_id: certificado.matricula_id,
            nota: parseInt(certificado.nota),
            lado_uno: certificado.lado_uno + "", //horas pedagogicas
            lado_dos:
              certificado.fecha_inicio + "_" + certificado.fecha_fin + "",
            tipo: this.certipo + "",
            taller_dias: certificado.taller_dias,
          },
        })
        .then((data) => {
          var idcertificado = parseInt(data.data.createCertificado.id);
          var codigo = "C" + alumno.dni + "-" + idmatricula + "EDU";
          this.$apollo
            .mutate({
              mutation: CERTIFICADO_UPDATE,
              variables: {
                id: idcertificado,
                update: {
                  codigo: codigo,
                  created_at: certificado.created_at,
                },
              },
            })
            .then((data) => {
              console.log(data.data.updateCertificado);
              if (data.data.updateCertificado) {
                emailjs.init(this.ecorreo.public);
                //  this.$router.go(0);

                var templateParams = {
                  to_name: alumno.nombre,
                  message: idcertificado,
                  curso: this.curso,
                  email: alumno.email,
                };
                emailjs
                  .send(this.ecorreo.service, this.ecorreo.template, templateParams)
                  .then((response) => {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.status == 200) {
                      this.$router.go(0);
                    }
                  });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Ha surgido un error" + data,
                });
              }
            });
        });
    },
    eliminarCertificado(id) {
      console.log(id);
      // console.log(CERTIFICADO_ELIMINAR);
      this.$swal({
        title: "Alerta",
        text: "¿Estas seguro de eliminar este registro?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: CERTIFICADO_ELIMINAR,
              variables: {
                id: id,
              },
            })
            .then((data) => {
              if (data.data.deleteCertificado.id >= 0) {
                console.log("Hecho");
                this.$router.go(0);
              } else {
                this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Ha surgido un error" + data,
                });
              }
            });
        }
      });
    },
    cargarDatosCertificado(id) {
      this.$apollo
        .query({
          query: CERTIFICADO_PDF,
          variables: {
            id: id,
          },
        })
        .then((data) => {
          this.ceditar.horas_creado = data.data.certificadoById.lado_uno;

          this.ceditar.fecha_inicio = data.data.certificadoById.lado_uno;
          this.ceditar.fecha_fin = data.data.certificadoById.lado_uno;
          this.ceditar.nota = data.data.certificadoById.nota;
          this.ceditar.certipo = data.data.certificadoById.tipo;

          const fechatotal = data.data.certificadoById.lado_dos;
          const fechasArray = fechatotal.split("_");
          this.ceditar.fecha_inicio = moment(fechasArray[0])
            .utc()
            .format("YYYY-MM-DD");
          this.ceditar.fecha_fin = moment(fechasArray[1])
            .utc()
            .format("YYYY-MM-DD");

          this.ceditar.fecha_creado = moment(
            data.data.certificadoById.created_at
          )
            .utc()
            .format("YYYY-MM-DD");

          this.ceditar.idcertificado_creado = data.data.certificadoById.id;
        });
    },
    editarCertificado(c) {
      this.$swal({
        title: "Alerta",
        text: "¿Estas seguro de editar este registro?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
      }).then((result) => {
        this.cargando = true;
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: CERTIFICADO_UPDATE,
              variables: {
                id: parseInt(c.idcertificado_creado),
                update: {
                  lado_uno: c.horas_creado,
                  created_at: c.fecha_creado,
                  nota: parseInt(c.nota),
                  lado_dos: c.fecha_inicio + "_" + c.fecha_fin,
                  tipo: c.certipo + "",
                },
              },
            })
            .then((data) => {
              console.log(data.data.updateCertificado);
              if (data.data.updateCertificado.id >= 0) {
                document.getElementById("close").click();

                this.$router.go(0);
              } else {
                this.cargando = false;
                this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Ha surgido un error" + data,
                });
              }
            });
        }
      });
    },
    capitalizarPalabras(texto) {
      const re = /(^|[^A-Za-zÁÉÍÓÚÜÑáéíóúüñ])(?:([a-záéíóúüñ])|([A-ZÁÉÍÓÚÜÑ]))|([A-ZÁÉÍÓÚÜÑ]+)/gu;
    return texto.replace(re,
        (m, caracterPrevio, minuscInicial, mayuscInicial, mayuscIntermedias) => {
            const locale = ['es', 'gl', 'ca', 'pt', 'en'];
            if (mayuscIntermedias)
                return mayuscIntermedias.toLocaleLowerCase(locale);
            return caracterPrevio
                 + (minuscInicial ? minuscInicial.toLocaleUpperCase(locale) : mayuscInicial);
        }
    );
    },

    generarPDF(id, idcurso, tipocurso, alumno) {
      moment.locale("es");
      let pdf = {
        nombre: (
          alumno.nombres +
          " " +
          alumno.a_paterno +
          " " +
          alumno.a_materno
        ).toLowerCase(),
        fecha: "",
        horas: "",
        tipo: tipocurso,
        codigoqr: "",
        cursoid: idcurso,
        id: id,
        tipocertificado: "",
        nota: "",
        fechai: "",
        fechaf: "",
      };
      /* pdf.nombre = pdf.nombre.replace(/(^\w{1})|(\s+\w{1})/g, (letra) =>
        letra.toUpperCase()
      );*/
      pdf.nombre = this.capitalizarPalabras(pdf.nombre);

      this.$apollo
        .query({
          query: CERTIFICADO_PDF,
          variables: {
            id: id,
          },
        })
        .then((data) => {
          console.log(data.data.certificadoById.id);
          pdf.fecha = moment(data.data.certificadoById.created_at)
            .utc()
            .format("LL");
          pdf.horas = data.data.certificadoById.lado_uno;
          pdf.tipocertificado = data.data.certificadoById.tipo;
          pdf.codigoqr = data.data.certificadoById.codigo;
          (pdf.id = data.data.certificadoById.id),
            (pdf.nota = data.data.certificadoById.nota);
          const fechatotal = data.data.certificadoById.lado_dos;
          const fechasArray = fechatotal.split("_");
          pdf.fechai = moment(fechasArray[0]).utc().format("LL");
          pdf.fechaf = moment(fechasArray[1]).utc().format("LL");

          console.log(pdf);
          window.open(
            "https://sistema.cersa.org.pe/certificadoscersa/certificadocip.php?nombre=" +
              pdf.nombre +
              "&fecha=" +
              pdf.fecha +
              "&horas=" +
              pdf.horas +
              "&tipo=" +
              pdf.tipo +
              "&codigoqr=" +
              pdf.codigoqr +
              "&cursoid=" +
              pdf.cursoid +
              "&id=" +
              pdf.id +
              "&fechai=" +
              pdf.fechai +
              "&fechaf=" +
              pdf.fechaf +
              "&nota=" +
              pdf.nota +
              "&tipocertificado=" +
              pdf.tipocertificado
          );
        });
    },
    randon() {
      this.certificado.nota = Math.floor(Math.random() * (19 - 16) + 16);
      console.log(this.certificado.nota);
    },

    recargar() {
      //  this.$router.go(0)
    },
  },
  mounted() {
    this.llamareEmailjs();
    this.llamarCurso();
    this.randon();
    this.listarAlumnos();
  },
};
</script>
