import gql from "graphql-tag";

export const LOGIN_ALUMNO = gql`
  query login($email: String!, $password: String!, $social: JSON!) {
    login(email: $email, password: $password, social: $social) {
      id
      authentication
      nombres
      email
    }
  }
`;

export const LISTAR_MIS_CURSOS = gql`
  query alumno($id: Int!) {
    alumno(id: $id) {
      cursos {
        id
        nombre_corto
        nombre_completo
        imagen
        docente {
          nombres
        }
      }
    }
  }
`;

export const  CERTIFICADO_PDF= gql`
  query certificadoById($id: Int!) {
    certificadoById(id: $id) {
      id
      codigo
      nota
      lado_uno
      lado_dos
      tipo
      created_at
    }
  }
`;
export const CERTIFICADO_ID_MIN = gql`
  query certificadoById($id: Int!) {
    certificadoById(id: $id) {
      id
      codigo
      lado_uno
      created_at
    }
  }
`;

export const MATRICULA_ID = gql`
  query matricula($id: Int!) {
    matricula(id: $id) {
      id
      alumno {
        id
        dni
        nombres
        a_paterno
        a_materno
        email
      }
      curso {
        id
        horas_pedagogicas
        nombre_corto
        nombre_completo
      }
    }
  }
`;
export const EXISTE_CERTIFICADO = gql`
  query certificadoByCodigo($codigo: String!) {
    certificadoByCodigo(codigo: $codigo) {
      codigo
    }
  }
`;

export const CERTIFICADO_ID = gql`
  query certificadoById($id: Int!) {
    certificadoById(id: $id) {
      id
      codigo
      lado_uno
      lado_dos
      tipo
      taller_dias
      created_at
      matricula {
        alumno {
          siglas
          nombres
          a_paterno
          a_materno
        }
        curso {
          id
          nombre_corto
          precio_suficiencia
          nombre_completo
          horas_pedagogicas
        }
      }
    }
  }
`;

export const LISTAR_ALUMNOS = gql`
  query matriculasByCursoId($curso_id: Int!) {
    matriculasByCursoId(curso_id: $curso_id) {
      id
      fecha_pago
      created_at

      alumno {
        dni
        nombres
        a_paterno
        a_materno
        profesion
        email
        celular
      }
      pago
      drive
      descuento
      descripcion
      user {
        nombres
        a_paterno
        a_materno
      }
      certificado {
        id
      }
    }
  }
`;

export const LISTA_CURSOS = gql`
  query cursos {
    cursos {
      id
      nombre_completo,
      horas_pedagogicas
    }
  }
`;

export const MATRICULAS = gql`
  query matriculas {
    matriculas {
      id
      fecha_pago
      created_at
      drive
      pago
      descuento
      descripcion
      alumno{
      dni,
      celular,
      email
      nombres,
      a_paterno,
      a_materno
    }
      user {
        nombres
      }
      curso {
        nombre_completo
        fecha_inicio
      }
    }
  }
`;

export const LISTAR_CLASES = gql`
  query modulosByCursoId($curso_id: Int!) {
    modulosByCursoId(curso_id: $curso_id) {
      id
      nombre
    }
  }
`;

export const EMAIL_JS_1 = gql`
  query user($id: Int!) {
    user(id:$id) {
      nombres
    a_paterno
    a_materno
    }
  }
`;

/*export const LISTAR_CLASES = gql`
  query modulosByCursoId($curso_id: Int!) {
    modulosByCursoId(curso_id: $curso_id) {
      id
      nombre
    }
  }
`;
export const LISTAR_CLASES = gql`
  query modulosByCursoId($curso_id: Int!) {
    modulosByCursoId(curso_id: $curso_id) {
      id
      nombre
    }
  }
`;
export const LISTAR_CLASES = gql`
  query modulosByCursoId($curso_id: Int!) {
    modulosByCursoId(curso_id: $curso_id) {
      id
      nombre
    }
  }
`;

*/
export const UN_CURSO = gql`
  query curso($id: Int!) {
    curso(id: $id) {
      id, 
      precio_suficiencia,
      horas_pedagogicas,
      nombre_completo
    }
  }
`;

export const LISTAR_CONTENIDO = gql`
  query archivosByModuloId($modulo_id: Int!) {
    archivosByModuloId(modulo_id: $modulo_id) {
      id
      orden
      tipo
      nombre
      link
      tamanio
      duracion
      is_public
    }
  }
`;
export const BUSCAR_POR_EMAIL = gql`
  query alumnoByEmail($email: String!) {
    alumnoByEmail(email: $email) {
      id,
      dni,
      nombres,
      a_paterno,
      a_materno
      email
    }
  }
`;

export const ALUMNOS = gql`
  query alumnos {
    alumnos {
      id
      dni
      nombres
      a_paterno
      a_materno
    }
  }
`;
export const ALUMNO_ID = gql`
  query alumno($id: Int!) {
    alumno(id: $id) {
      id
      dni
      nombres
      a_paterno
      a_materno
    }
  }
`;
export const MATRICULAS_POR_ALUMNO = gql`
  query matriculasByAlumnoIdEstado($alumno_id: Int!) {
    matriculasByAlumnoIdEstado(alumno_id: $alumno_id) {
      id
      curso {
        id
        nombre_completo,
        precio_suficiencia
      }
      certificado {
        id
        codigo
        lado_uno
      }
    }
  }
`;
export const TODOS_CERTIFICADOS = gql`
  query certificados {
    certificados {
      id
      codigo
      matricula_id
      lado_uno
      created_at
      matricula {
        alumno {
          dni
          nombres
          a_paterno
          a_materno
        }
        curso {
          nombre_completo
        }
      }
    }
  }
`;
export const COMENTARIO_CLASE = gql`
  query comments($archivo_id: Int!) {
    comments(archivo_id: $archivo_id) {
      id
      comentario
      created_at
      persona {
        nombres
        a_paterno
      }
    }
  }
`;
