<template>

  <div>
    <Navbar />
    <div class="page-heading"><h3>Lista de Cursos</h3></div>
    <div class="page-content">
      <section class="section">
        <div class="row text-center">
          <div v-show="cargando == true">
            <button class="btn btn-warning" type="button" disabled="">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body table-responsive px3 py-4-5">
                <table class="table" id="sampleTable">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="curso in miscursos" :key="curso.id">
                      <th scope="row">{{ curso.id }}</th>
                      <td>{{ curso.nombre_completo }}</td>
                      <td>
                        <button
                          class="btn btn-primary"
                          @click="irA('cursos.alumnos.index', curso.id)"
                        >
                          Alumnos
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { LISTA_CURSOS } from "@/graphql/queries";
require("datatables.net-buttons/js/dataTables.buttons")();
require("datatables.net-buttons/js/buttons.html5.js")();
import $ from "jquery";
import jszip from "jszip/dist/jszip";
window.JSZip = jszip;

export default {
  name: "Lista",
  inject: ["state", "setUser"],
  data() {
    return {
      miscursos: [],
      cargando: false,
    };
  },
  methods: {
    tabla() {
      this.$nextTick(() => {
        var table = $("#sampleTable").DataTable({
          dom:
            "<'row '<'col-sm-12 mb-3 'B>><'row '<'col-sm-12 col-md-6 'l><'col-sm-12 col-md-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
          buttons: [
            {
              extend: "excelHtml5",
              text: "<i class='bi bi-file-earmark-excel'></i> Excel",
              titleAttr: "Exportar a Excel",
              className: "btn btn-success",
              title: "Lista de Cursos",
            },
          ],
        });
        table.order([0, "desc"]).draw();
      });
    },
    listarCursos() {
      this.cargando = true;
      this.$apollo
        .query({
          query: LISTA_CURSOS,
        })
        .then((data) => {
          this.miscursos = data.data.cursos;
          this.tabla();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    irA(name, parms) {
      this.$router.push({
        name: name,
        params: {
          idc: parms,
        },
      });
    },
  },

  mounted() {
    //this.listarCursos
    this.listarCursos();
  },
};
</script>

<style></style>
