<template>
  <div>
    
    <div id="main">
      <header class="mb-3">
        <a href="#" class="burger-btn d-block d-xl-none">
          <i class="bi bi-justify fs-3"></i>
        </a>
      </header>

     <router-view />
    
    <!--
      <footer>
        <div class="footer clearfix mb-0 text-muted">
          <div class="float-start">
            <p>2022 &copy; Cersa</p>
          </div>
          <div class="float-end">
            <p>
              Creado con
              <span class="text-danger"><i class="bi bi-heart"></i></span> by
              <a href="#">Jose Ramirez</a>
            </p>
          </div>
        </div>
      </footer>-->
    </div>


  </div>
</template>

<script>
import { state, mutations } from "@/modules/auth/store";
export default {
  name: "App",
  provide: {
    state,
    setToken: mutations.setToken,
    setUser: mutations.setUser,
  },
  data: () => ({
    //
  }),
};
</script>
