import Vue from 'vue'

export const state = Vue.observable({
  token: localStorage.getItem("token"),
  user: JSON.parse(localStorage.getItem("auth")),
})

export const mutations = {
  setToken: (token) => (state.token = token),
  setUser: (user) => (state.user = user),
}