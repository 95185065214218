import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import VueRouter from 'vue-router'
//Vue.use(VueRouter)
//import vuetify from "./plugins/vuetify";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
window.Vue = Vue;
import "datatables.net-bs4";

import { createProvider } from "./vue-apollo";
import Navbar from "@/components/Navbar";


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

Vue.component("Navbar", Navbar);

Vue.config.productionTip = false;

new Vue({
  router,
  //vuetify,
  //apolloProvider,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
