<template>
  <div>
    <Navbar />
    <div class="page-heading">
      <h3>Matriculas</h3>
    </div>
    <div class="page-content">
      <section class="section">
        <div class="row text-center">
          <div v-show="cargando == true">
            <button class="btn btn-warning" type="button" disabled="">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body px3 py-4-5">
                <table class="table" id="sampleTable">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">DNI</th>
                      <th scope="col">NOMBRE</th>
                      <th scope="col">CELULAR</th>
                      <th scope="col">EMAIL</th>
                      <th scope="col">FECHA_PAGO</th>
                      <th scope="col">FECHA_MATRICULA</th>
                      <th scope="col">NOMBRE_CURSO</th>
                      <th scope="col">MONTO</th>
                      <th scope="col">DESCUENTO</th>
                      <th scope="col">BANCO</th>
                      <th scope="col">OPERACIÓN</th>
                      <th scope="col">PROM</th>
                      <th scope="col">DETALLE</th>
                      <th scope="col">USUARIO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="m in mismatriculas" :key="m.id">
                      <th scope="row">{{ m.id }}</th>
                      <td>{{ m.alumno.dni }}</td>

                      <td>
                        {{ m.alumno.nombres }} {{ m.alumno.a_paterno }}
                        {{ m.alumno.a_materno }}
                      </td>
                        <td>{{ m.alumno.celular }}</td>
                      <td>{{ m.alumno.email }}</td>

                      <td>{{ m.fecha_pago }}</td>
                      <td>{{ m.created_at }}</td>
                      <td style="font-size: 12px">
                        {{ m.curso.nombre_completo }}
                      </td>
                      <td>{{ m.pago }}</td>
                      <td>{{ m.descuento }}</td>
                      <td>{{ banco(m.descripcion) }}</td>
                      <td>{{ operacion(m.descripcion) }}</td>
                      <td>{{ promo(m.descripcion) }}</td>
                      <td style="font-size: 12px">{{ m.descripcion }}</td>
                      <td>{{ m.user.nombres }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { MATRICULAS } from "@/graphql/queries";
require("datatables.net-buttons/js/dataTables.buttons")();
require("datatables.net-buttons/js/buttons.html5.js")();
import $ from "jquery";
import jszip from "jszip/dist/jszip";
window.JSZip = jszip;

export default {
  name: "Lista",
  inject: ["state", "setUser"],
  data() {
    return {
      mismatriculas: [],
      cargando: false,
      usuario: "",
    };
  },
  methods: {
    tabla() {
      this.$nextTick(() => {
        var table = $("#sampleTable").DataTable({
          dom:
            "<'row '<'col-sm-12 mb-3 'B>><'row '<'col-sm-12 col-md-6 'l><'col-sm-12 col-md-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
          buttons: [
            {
              extend: "excelHtml5",
              text: "<i class='bi bi-file-earmark-excel'></i> Excel",
              titleAttr: "Exportar a Excel",
              className: "btn btn-success",
              title: "Lista de Cursos",
            },
          ],
        });
        table.order([0, "desc"]).draw();
      });
    },
    listarCursos() {
      this.cargando = true;
      this.$apollo
        .query({
          query: MATRICULAS,
        })
        .then((data) => {
          this.mismatriculas = data.data.matriculas;

          this.tabla();
        })
        .catch((error) => {
          console.log(error);
          this.cargando = false;
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    banco(palabra) {
      const divide = palabra + "";
      const uno = divide.split(" ", 1);

      const palabrauno = uno[0];
      const final1 = palabrauno.split("-", 1);
      const n = final1[0];
      var banco = "";
      if (
        n == "BBVA" ||
        n == "BN" ||
        n == "BCP" ||
        n == "YAPE" ||
        n == "YAPE" ||
        n == "WU" ||
        n == "PAYU" ||
        n == "PAYPAL" ||
        n == "SCOTI" ||
        n == "PLIN"
      ) {
        banco = n;
      } else {
        banco = "NO REGISTRADO";
      }
      return banco;
    },
    operacion(palabra) {
      const divide = palabra + "";
      const uno = divide.split(" ", 1);
      const palabrauno = uno[0];
      const final2 = palabrauno.split("-", 2);
      return final2[1];
    },
    promo(palabra) {
      const divide = palabra + "";
      const uno = divide.split(" ", 1);
      const palabrauno = uno[0];
      const final2 = palabrauno.split("-", 3);
      return final2[2];
    },
  },
  created() {
    this.listarCursos();
  },
};
</script>

<style></style>
