import gql from "graphql-tag";

export const ALUMNO_UPDATE = gql`
  mutation updateAlumno($id: Int!, $update: JSON!) {
    updateAlumno(id: $id, update: $update) {
      id
      siglas
      nombres
      a_paterno
      a_materno
      nacimiento
      dni
      celular
      direccion
      pais
      departamento
      provincia
      distrito
      profesion
      email
    }
  }
`;

export const CREAR_CERTIFICADO = gql`
mutation createCertificado(
  $codigo: String!,
  $matricula_id: Int!,
  $nota: Float!,
  $lado_uno: String!,
  $lado_dos: String!,
  $tipo: String!,
  $taller_dias: String!) {
    createCertificado(
      codigo: $codigo,
      matricula_id: $matricula_id,
      nota:  $nota,
      lado_uno: $lado_uno,
      lado_dos:  $lado_dos,
      tipo: $tipo,
      taller_dias:   $taller_dias
      )  {
        id,
        codigo
      }
}
`;

export const CERTIFICADO_UPDATE = gql`
  mutation updateCertificado($id: Int!, $update: JSON!) {
    updateCertificado(id: $id, update: $update) {
      id,codigo,created_at
    }
  }
`;

export const CERTIFICADO_ELIMINAR = gql`
  mutation deleteCertificado($id: Int!) {
    deleteCertificado(id: $id) {
      id
    }
  }
`;

