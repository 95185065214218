<template>
  <div>
    <Navbar />
    <div class="page-heading"><h3>Certificados</h3></div>
    <div class="page-content">
      <section class="section">
        <div class="row text-center">
          <div v-show="cargando == true">
            <button class="btn btn-warning" type="button" disabled="">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body table-responsive px3 py-4-5">
                <table class="table" id="sampleTable">
                  <thead>
                    <tr>
                      <th scope="col">#</th>

                      <th style="width: 400px !important;">Alumno</th>
                      <th scope="col">Curso</th>
                      <th scope="col">Código</th>
                      <th scope="col">Emisión</th>
                      <th scope="col">Horas</th>
                      <th style="width: 5px !important;">Descarga</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="c in certificados" :key="c.id">
                      <th scope="row">{{ c.id }}</th>

                      <td>
                        {{ c.matricula.alumno.dni }}-
                        {{ c.matricula.alumno.nombres }}
                        {{ c.matricula.alumno.a_paterno }}
                        {{ c.matricula.alumno.a_materno }}
                      </td>
                      <td>{{ c.matricula.curso.nombre_completo }}</td>
                      <td>{{ c.codigo }}</td>
                      <td>
                        {{ new Date(c.created_at).toLocaleDateString() }}
                      </td>
                      <td>{{ c.lado_uno }}</td>
                      <td>
                        <a
                          :href="
                            'https://cersa.org.pe/diplomas/?codigo=' + c.id
                          "
                          target="_bank"
                          class="btn btn-success"
                          title="Descargar"
                        >
                          <svg
                            class="svg-inline--fa fa-cloud-download-alt fa-w-20 fa-fw select-all"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="cloud-download-alt"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z"
                            ></path></svg
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { TODOS_CERTIFICADOS } from "@/graphql/queries";
require("datatables.net-buttons/js/dataTables.buttons")();
require("datatables.net-buttons/js/buttons.html5.js")();
import $ from "jquery";
import jszip from "jszip/dist/jszip";
window.JSZip = jszip;

export default {
  name: "certificados.index",
  data() {
    return {
      certificados: [],
      cargando: false,
    };
  },
  methods: {
    tabla() {
      this.$nextTick(() => {
        var table = $("#sampleTable").DataTable({
          dom:
            "<'row '<'col-sm-12 mb-3 'B>><'row '<'col-sm-12 col-md-6 'l><'col-sm-12 col-md-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
          buttons: [
            {
              extend: "excelHtml5",
              text: "<i class='bi bi-file-earmark-excel'></i> Excel",
              titleAttr: "Exportar a Excel",
              className: "btn btn-success",
              title: "Lista de Cursos",
            },
          ],
        });
        table.order([0, "desc"]).draw();
      });
    },
    listarCertificados() {
      this.cargando = true;

      this.$apollo
        .query({
          query: TODOS_CERTIFICADOS,
        })
        .then((data) => {
          this.certificados = data.data.certificados;
          this.tabla();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
  },

  mounted() {
    this.listarCertificados();
  },
};
</script>

<style></style>
