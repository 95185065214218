<template>
  <div>
    <Navbar />
    <div class="page-heading">
      <div class="page-title">
        <div class="row">
          <div class="col-12 col-md-6 order-md-1 order-last">
            <h3>Dashboard</h3>
            <p class="text-subtitle text-muted">Estadisticas</p>
          </div>
        </div>
      </div>

      <!-- Basic Tables start -->
      <section class="section">
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Hola {{ fullname }}</h4>
                <p class="">Bienvenido a cersa</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Basic Tables end -->
    </div>
  </div>
</template>

<script>


import { ALUMNO_UPDATE } from "@/graphql/mutations";
export default {
  name: "home",
  inject: ["state", "setUser"],
  computed: {
    fullname() {
      return `${this.state.user.nombres} `;
    },
  },
  data() {
    return {
      user: {
        nombres: "",
        a_paterno: "",
      },
      password: "",
      //email
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
   
    UpdateUser(closeModalAction) {
      if (this.password) {
        this.user.password = this.password;
      } else {
        this.$apollo
          .mutate({
            mutation: ALUMNO_UPDATE,
            variables: {
              id: this.state.user.id,
              update: {
                email: "sistemas@cersa.org.pe",
                nombres: this.user.nombres,
                a_paterno: this.user.a_paterno,
              },
            },
          })
          .then((data) => {
            this.state.user = data.data.updateAlumno;
            this.setUser(this.state.user);
            closeModalAction(false);
          });
      }
    },
  },
};
</script>
