<template>
  <div id="sidebar" class="active">
    <div class="sidebar-wrapper active">
      <div class="sidebar-header">
        <div class="d-flex justify-content-between">
          <div class="logo">
            <a href="#"
              ><img
                src="https://cersa.org.pe/capacitaciones/sites/default/files/nuevologo150.png"
                alt="Logo"
                srcset=""
            /></a>
          </div>
          <div class="toggler">
            <a href="#" class="sidebar-hide d-xl-none d-block"
              ><i class="bi bi-x bi-middle"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="sidebar-menu">
        <ul class="menu">
          <li class="sidebar-title">CERTIFICADOS</li>
          <li class="sidebar-item">
            <a href="#" @click="irA('cursos.index')" class="sidebar-link">
              <i class="bi bi-stack"></i>
              <span>Certificado por Curso</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a href="#" @click="irA('alumnos.index')" class="sidebar-link">
              <i class="bi bi-person-badge-fill"></i>
              <span>Certificado por Alumno</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a href="#" @click="irA('certificados.index')" class="sidebar-link">
              <i class="bi bi-grid-fill"></i>
              <span>Todos los Certifcados</span>
            </a>
          </li>
          <li class="sidebar-title">MATRICULAS</li>
          <li class="sidebar-item">
            <a href="#" @click="irA('matriculas.index')" class="sidebar-link">
              <i class="bi bi-stack"></i>
              <span>Lista de matriculas</span>
            </a>
          </li>
        </ul>
      </div>
      <button class="sidebar-toggler btn x"><i data-feather="x"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      drawer: false,
      group: 0,
    };
  },
  methods: {
    irA(name) {
      this.$router.push({ name: name }).catch(() => {});
    },
  },
};
</script>
