<template>
  <div>
    <Navbar />
    <div class="page-heading">
      <h3>Lista de Alumnos</h3>
    </div>
    <div class="page-content">
      <section class="section">
        <div class="row text-center">
          <div v-show="cargando == true">
            <button class="btn btn-warning" type="button" disabled="">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          </div>
        </div>
       <!--   <div class="row">
        
          <div class="col-md-12">
            <input
              class="col-md-6"
              type="email"
              id="correo"
              name="correo"
              required
              v-model="emailalumno"
              placeholder="Ingrese email"
            />
            <button class="btn btn-primary" @click="buscarAlumno()">
              Buscar
            </button>
          </div>
          <div class="col-md-12" v-show="encontrado">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th>DNI</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Email</th>
                  <th scope="col">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{{ alumno.id }}</th>
                  <th>{{ alumno.dni }}</th>
                  <td>
                    {{ alumno.nombres }} {{ alumno.a_paterno }}
                    {{ alumno.a_materno }}
                  </td>
                  <th>{{ alumno.email }}</th>
                  <td>
                    <button
                      class="btn btn-primary"
                      @click="irA('alumnos.cursos.index', alumno.id)"
                    >
                      Cursos
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>-->
        <hr>
        <div class="row mt-4">
         <!-- <div class="col-md-12 mt-4">
            <div class="btn btn-primary" @click="listarAlumnos()">Cargar todos los alumnos</div>
          </div>-->
          <div class="col-md-12">
            <div class="card">
              <div class="card-body px3 py-4-5">
                <table class="table" id="sampleTable">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th>DNI</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="alumno in alumnos" :key="alumno.id">
                      <th scope="row">{{ alumno.id }}</th>
                      <th>{{ alumno.dni }}</th>
                      <td>
                        {{ alumno.nombres }} {{ alumno.a_paterno }}
                        {{ alumno.a_materno }}
                      </td>
                      <td>
                        <button
                          class="btn btn-primary"
                          @click="irA('alumnos.cursos.index', alumno.id)"
                        >
                          Cursos
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ALUMNOS } from "@/graphql/queries";
import { BUSCAR_POR_EMAIL } from "@/graphql/queries";
require("datatables.net-buttons/js/dataTables.buttons")();
require("datatables.net-buttons/js/buttons.html5.js")();
import $ from "jquery";
import jszip from "jszip/dist/jszip";
window.JSZip = jszip;

export default {
  name: "Lista",
  inject: ["state", "setUser"],
  data() {
    return {
      alumnos: [],
      cargando: false,
      emailalumno: "",
      alumno: "",
      encontrado: false,
    };
  },
  methods: {
    tabla() {
      this.$nextTick(() => {
        var table = $("#sampleTable").DataTable({
          dom:
            "<'row '<'col-sm-12 mb-3 'B>><'row '<'col-sm-12 col-md-6 'l><'col-sm-12 col-md-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
          buttons: [
            {
              extend: "excelHtml5",
              text: "<i class='bi bi-file-earmark-excel'></i> Excel",
              titleAttr: "Exportar a Excel",
              className: "btn btn-success",
              title: "Lista de Cursos",
            },
          ],
        });
        table.order([0, "desc"]).draw();
      });
    },
    listarAlumnos() {
      this.cargando = true;
      this.$apollo
        .query({
          query: ALUMNOS,
        })
        .then((data) => {
          this.alumnos = data.data.alumnos;
          this.tabla();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    buscarAlumno() {
      this.alumno=""
      this.cargando = true;
      this.$apollo
        .query({
          query: BUSCAR_POR_EMAIL,

          variables: {
            email: this.emailalumno,
          },
        })
        .then((data) => {
          if (data.data.alumnoByEmail != null) {
            this.alumno = data.data.alumnoByEmail;
            this.encontrado = true;
          } else {
            this.cargando = false;
            this.$swal({
              title: "Error!",
              text: "Alumno no registrado",
              icon: "error",
              confirmButtonText: "ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    irA(name, parms) {
      this.$router.push({
        name: name,
        params: {
          ida: parms,
        },
      });
    },
  },
  created() {
     this.listarAlumnos();
  },
};
</script>

<style></style>
