import Vue from 'vue'
import VueRouter from 'vue-router'

import SignInPage from "../modules/auth/pages/SignIn";
import ListaCursos from "../modules/cursos/Index.vue";
import ListaAlumnos from "../modules/alumnos/index.vue";
import AlumnosporCurso from "../modules/alumnos/AlumnosporCurso.vue";
import CursosporAlumno from "../modules/alumnos/CursosporAlumno.vue";
import ListadeCertificados from "../modules/certificados/Index.vue";
import ListaMatriculas from "../modules/matriculas/Index.vue";
import Usuario from "../modules/user/Usuario";
import { state } from '@/modules/auth/store'

Vue.use(VueRouter)

const routes =[
  {
    path: "/",
    name:'usuario.index',
    component: Usuario,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cursos",
    component: ListaCursos,
    name:'cursos.index'
  },
  {
    path: "/cursos/:idc/alumnos",
    component: AlumnosporCurso,
    name:'cursos.alumnos.index'
  },
  
  {
    path: "/alumnos",
    component: ListaAlumnos,
    name:'alumnos.index'
  },
  {
    path: "/matriculas",
    component: ListaMatriculas,
    name:'matriculas.index'
  },
  {
    path: "/alumnos/:ida/cursos",
    component: CursosporAlumno,
    name:'alumnos.cursos.index'
  },
  {
    path: "/certificados",
    component: ListadeCertificados,
    name:'certificados.index'
  },
  {
    path: "/signin",
    component: SignInPage,
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (state.token) {
      return next()
    }
    next('/signin')
  } else {
    next()
  }
})

export default router
