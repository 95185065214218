<template>
  <div >
    <div class="row  text-center mt-4">
      <div class="col-md-3"></div>
      <div class="col-md-4">
        <div >
          <div class="auth-logo">
            <a href="#"
              ><img
                src="https://cersa.org.pe/capacitaciones/sites/default/files/nuevologo150.png"
                alt="Logo"
            /></a>
          </div>
          <h1 class="auth-title">Log in.</h1>
          <p class="auth-subtitle mb-5">Ingrese credenciales</p>

          <form ref="signInForm" @submit.prevent="signIn">
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="email"
                v-model="user.email"
                label="Email"
                :rules="emailRules"
                class="form-control form-control-xl"
              />
              <div class="form-control-icon">
                <i class="bi bi-person"></i>
              </div>
            </div>
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="password"
                v-model="user.password"
                label="Contraseña"
                :rules="passwordRules"
                class="form-control form-control-xl"
                placeholder="Password"
              />
              <div class="form-control-icon">
                <i class="bi bi-shield-lock"></i>
              </div>
            </div>

            <button
              type="submit"
              class="btn btn-primary btn-block btn-lg shadow-lg mt-5"
            >
              Log in
            </button>
          </form>
        </div>
      </div> 
       <div class="col-md-4"></div>    
    </div>
  </div>
</template>
<script>
import { LOGIN_ALUMNO } from "@/graphql/queries";
export default {
  name: "SignIn",

  inject: ["setToken", "setUser"],
  data() {
    return {
      user: {
        email: "",
        password: "",
        social: {
          type: "mobil",
        },
      },
      emailRules: [
        (v) => !!v || "Email es requerido",
        (v) => /.+@.+/.test(v) || "Email invalido",
      ],
      passwordRules: [
        (v) => !!v || "Contraseña requerida",
        (v) => (v && v.length >= 6) || "Contraseña minimo de 6 caracteres",
      ],
    };
  },
  methods: {
    signIn() {
      this.$apollo
        .query({
          query: LOGIN_ALUMNO,
          variables: {
            email: this.user.email,
            password: this.user.password,
            social: this.user.social,
          },
        })
        .then((data) => {
          const {
            login: { authentication },
          } = data.data;
          this.user = data.data.login;
          localStorage.token = authentication;
          localStorage.setItem("auth", JSON.stringify(this.user));
          this.setUser(this.user);
          this.setToken(authentication);
          this.$router.push("/");
        });
    },
  },
};
</script>
